import {LowQualAntichrist, LowQualChange, LowQualFirewall, LowQualMyopia} from "../../img";
import './songs.css';
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";

const Songs = () => {
  let navigate = useNavigate();
  let location = useLocation();

  let dotRef = useRef(null);
  let firewallRef = useRef(null);
  let changeRef = useRef(null);
  let antichristRef = useRef(null);
  let myopiaRef = useRef(null);

  const moveDot = (imageRef) => {
    if (dotRef.current && imageRef.current) {
      // Get the bounding box of the clicked image
      const imgRect = imageRef.current.getBoundingClientRect();
      // Get the bounding box of the container
      const containerRect = imageRef.current.parentElement.getBoundingClientRect();
      // Calculate the top position of the dot relative to the container
      const top = imgRect.top - containerRect.top - dotRef.current.clientHeight;
      // Calculate the left position of the dot relative to the container
      const left = imgRect.left - containerRect.left + (imgRect.width / 2) - (dotRef.current.clientWidth / 2);

      // Set the dot position
      dotRef.current.style.top = `${top}px`;
      dotRef.current.style.left = `${left}px`;
    }
  };

  useEffect(() => {
    let path = location.pathname.split('/')[1];
    if (path === 'the-firewall') {
      moveDot(firewallRef)
    } else if (path === 'change-command') {
      moveDot(changeRef)
    } else if (path === 'antichrist') {
      moveDot(antichristRef)
    } else if (path === 'myopia') {
      moveDot(myopiaRef)
    } else {
      moveDot(myopiaRef)
    }
  }, [location]);

  return (
    <>
      <div className={'songContainer'}>
        <div ref={dotRef} className={'dot'}>
          •
        </div>
        <img ref={firewallRef} className={'songImg'} src={LowQualFirewall} onClick={() => {
          navigate('/the-firewall')
          moveDot(firewallRef)
        }}/>
        <img ref={changeRef} className={'songImg'} src={LowQualChange} onClick={() => {
          navigate('/change-command')
          moveDot(changeRef)
        }}/>
        <img ref={antichristRef} className={'songImg'} src={LowQualAntichrist} onClick={() => {
          navigate('/antichrist')
          moveDot(antichristRef)
        }}/>
        <img ref={myopiaRef} className={'songImg'} src={LowQualMyopia} onClick={() => {
          navigate('/myopia')
          moveDot(myopiaRef)
        }}/>
      </div>
    </>
  )
}

export default Songs;