import './announcement.css'
import {InstaLogo, SpotifyLogo, TikTokLogo} from "../../img";

const Announcement = (props) => {
  const {songName, URL, img} = props;

  return (
    <>
      <div className={'announcementText'}>
        <div>{songName}</div>
        <div>OUT NOW!</div>
        <a className={'btn preSaveBtn'} style={{borderImage: `url(${img}) 7 round`}}
           href={`https://distrokid.com/hyperfollow/bloatware/${URL}`}>LISTEN HERE</a>
        {/*<a className={'btn musicVideoBtn'}*/}
        {/*   href={'https://www.youtube.com/watch?v=bbv10y5mxJI'}>MUSIC VIDEO</a>*/}
        <div className={'socialContainer'}>
          <a className={'btn socialBtn spotifyBtn'} rel={"noreferrer"}
             href={'https://open.spotify.com/artist/79LPtRg7hftiiUbmeBM3r0?si=X5RCwhVcQJeJCNV3v_4sbA'}
             target={"_blank"}>
            <img className={'socialImg spotifyLogo'} src={SpotifyLogo} alt={'Spotify logo'}/>
          </a>
          <a className={'btn socialBtn instaBtn'} rel={"noreferrer"}
             href={'https://instagram.com/bloatwaresounds'} target={"_blank"}>
            <img className={'socialImg instaLogo'} src={InstaLogo} alt={'Instagram logo'}/>
          </a>
          <a className={'btn socialBtn tikTokBtn'} rel={"noreferrer"}
             href={'https://www.tiktok.com/@bloatwaresounds'} target={"_blank"}>
            <img className={'socialImg tikTokLogo'} src={TikTokLogo} alt={'TikTok logo'}/>
          </a>
        </div>
      </div>
    </>
  )
}

export default Announcement;