import './App.css'
import './AppMobile.css';
import './fonts/index.css'
import {
  LowQualAntichrist,
  LowQualChange,
  LowQualFirewall,
  LowQualMyopia,
  OrbitRing,
} from "./img";
import { SatelliteStyle, SatelliteMobileStyle } from "./stylesheets";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {blah1, blah2, blah3} from './sound/index';
import {useEffect, useRef, useState} from "react";
import Songs from "./components/songs/Songs";
import AnimLogo from "./components/animLogo/AnimLogo";
import Announcement from "./components/announcement/Announcement";

function App() {
    // Elements
    const referenceDiv = useRef(null);
    const [marginValue, setMarginValue] = useState(0);

    const handleResize = () => {
        if (referenceDiv.current) {
            const margin = referenceDiv.current.offsetHeight
            setMarginValue(margin);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        // handleResize();
    }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={
            <div className={'flowers'}></div>
          }/>
          <Route exact path="/myopia" element={
            <div className={'flowers'}></div>
          }/>
          <Route exact path="/change-command" element={
            <div className={'stars'}></div>
          }/>
          <Route exact path="/the-firewall" element={
            <div className={'fire'}></div>
          }/>
          <Route exact path="/antichrist" element={
            <div className={'skulls'}></div>
          }/>
        </Routes>
        <div className={'homeContainer'}>
          <Routes>
            <Route exact path="/the-firewall" element={
              <div id={'blahEmoji'} className={'blahEmoji'}>
                🗣️
              </div>
            }/>
            <Route exact path="/change-command" element={
              <div ref={referenceDiv} className={'orbit'}>
                <div className={'satelliteContainer'}>
                  <div></div>
                  <div className={'satellite'}>🛰️</div>
                </div>

                <img className={'ring'} src={OrbitRing} alt={'Dashed line slowly spinning circle'}></img>
              </div>
            }/>
          </Routes>

          <Songs/>
          <AnimLogo/>
          <Routes>
            <Route exact path="/" element={<Announcement songName={'MYOPIA'} URL={'myopia'} img={LowQualMyopia}/>}/>
            <Route exact path="/the-firewall"
                   element={<Announcement songName={'THE FIREWALL'} URL={'the-firewall'} img={LowQualFirewall}/>}/>
            <Route exact path="/change-command"
                   element={<Announcement songName={'CHANGE COMMAND'} URL={'change-command'} img={LowQualChange}/>}/>
            <Route exact path="/antichrist"
                   element={<Announcement songName={'ANTICHRIST'} URL={'antichrist'} img={LowQualAntichrist}/>}/>
            <Route exact path="/myopia"
                   element={<Announcement songName={'MYOPIA'} URL={'myopia'} img={LowQualMyopia}/>}/>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
