import {DistortionText} from "react-text-fun";
import {iOS} from "../../functions/functions";
import {blah1, blah2, blah3} from "../../sound";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import './animLogo.css';

const AnimLogo = (props) => {
  const location = useLocation();

  let blah1Sound = new Audio(blah1)
  let blah2Sound = new Audio(blah2)
  let blah3Sound = new Audio(blah3)

  let sounds = [blah1Sound, blah2Sound, blah3Sound];
  let anims = ['blahEmoji-anim1', 'blahEmoji-anim2', 'blahEmoji-anim3']
  let [playing, setPlaying] = useState(false);
  let [number, setNumber] = useState(0);

  function playBlah() {
    setPlaying(true)
    document.getElementById('blahEmoji').classList.add(anims[number])
    sounds[number].play().then(r => {
      window.setTimeout(() => {
        if (document.getElementById('blahEmoji') !== null) {
          document.getElementById('blahEmoji').classList.remove(anims[number])
          setNumber(number += 1)
          if (number > sounds.length -1) {
            setNumber(0)
          }
        }
        setPlaying(false)
      }, 450)
      // document.getElementById('blahEmoji').classList.remove('blahEmoji-anim')
    })
  }

  function isFirewall() {
    return location.pathname.split('/')[1] === "the-firewall"
  }

  return (
    <>
      <div className={"textContainer"} style={{
        animation: iOS() ? 'none' : 'border-gradient 5s linear infinite',
        cursor: isFirewall() ? 'pointer' : 'default',
      }} onClick={() => {
        if (!playing && isFirewall()) {playBlah()}
      }}>
        <DistortionText
          id='bloatwareText'
          text={"B   L   O"}
          family="ConsolasBold"
          fontWeight="bold"
          distortX={0.75}
          distortY={0.25}
          volatitlity={5}
          rotation={25}
          // noiseVolatility={5}
          paddingLeft={25}
          paddingRight={70}
          fill="white"
          get2dContext={ctx => {
            ctx.canvas.width = "100px"
          }}
          speed={iOS() ? 0.015 : 0.075}
        />
        <DistortionText
          id='bloatwareText'
          text={"A   T   W"}
          family="ConsolasBold"
          fontWeight="bold"
          distortX={0.5}
          distortY={0.5}
          volatitlity={4}
          rotation={45}
          paddingLeft={25}
          paddingRight={70}
          // noiseVolatility={5}
          fill="white"
          speed={iOS() ? 0.015 : 0.075}
        />
        <DistortionText
          id='bloatwareText'
          text={"A   R   E"}
          family="ConsolasBold"
          fontWeight="bold"
          distortX={0.25}
          distortY={0.75}
          volatitlity={7}
          rotation={-45}
          paddingLeft={25}
          paddingRight={70}
          // noiseVolatility={5}
          fill="white"
          speed={iOS() ? 0.015 : 0.075}
        />
      </div>
    </>
  )
}

export default AnimLogo;